import gql from "graphql-tag";

// GET GROUP BY ID TEACHER
export const GET_GROUP = gql`
  query($teacher_id: Int!) {
    grupByTeacher(teacher_id: $teacher_id) {
      id
      nama
      pelatihan {
        id
        nama
      }
      mentor {
        id
        name
      }
    }
  }
`;

//all group
export const NAME_GROUP = gql`
  query($page: Int!, $first: Int!, $search: String) {
    allGrup(page: $page, first: $first, search: $search) {
      data {
        id
        nama
      }
    }
  }
`;

//all group
export const GROUP_BY_EVENT = gql`
  query($pelatihan_id: Int!) {
    grupByPelatihan(pelatihan_id: $pelatihan_id) {
      id
      nama
    }
  }
`;

// UPDATE GRUP
export const EDIT_GROUP = gql`
  mutation($id: ID!, $nama: String, $keterangan: String, $nilai_objektif_mentor: Int, $nilai_objektif_teacher: Int, $mentor_id: Int) {
    updateGrup(
      id: $id
      nama: $nama
      keterangan: $keterangan
      mentor_id: $mentor_id
      nilai_objektif_teacher: $nilai_objektif_teacher
      nilai_objektif_mentor: $nilai_objektif_mentor
    ) {
      id
      nama
      nilai_objektif_teacher
      pelatihan {
        id
        nama
      }
      mentor {
        id
        name
      }
    }
  }
`;

//DELETE STUDENT
export const DELETE_GROUP = gql`
  mutation($id: ID!) {
    deleteGrup(id: $id) {
      id
    }
  }
`;
