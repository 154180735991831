<template>
  <div class="pa-3" v-if="role == 3">
    <v-row class="mb-5">
      <v-progress-linear
        :active="loadTable"
        :indeterminate="loadTable"
        absolute
        top
        color="orange"
      ></v-progress-linear>
      <v-col cols="12" md="2" class="pb-0 mb-0"
        ><label for="event">Pilih kelompok</label></v-col
      >
      <v-col cols="12" md="10" class="pb-0 mb-0" v-if="allGrup">
        <v-select
          :options="allGrup.data"
          :reduce="nama => nama.id"
          v-model="select_kelompok"
          @search="query => (find = query)"
          label="nama"
          class="white"
          @input="get_group(select_kelompok)"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="id_group">
      <FileKelompok v-bind:id_group="id_group" />
    </div>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileKelompok from "../../components/Student/file/fileKelompok";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import { NAME_GROUP } from "../../graphql/Group";
import { FILE_GROUP } from "../../graphql/Student";
export default {
  apollo: {
    allGrup: {
      query: NAME_GROUP,
      variables() {
        return {
          page: 1,
          first: 10,
          search: this.find
        };
      }
    },
    fileShare: {
      variables() {
        return {
          grup_id: this.grup_id,
          share_to_grup: 1
        };
      },
      query: FILE_GROUP,
      skip() {
        return !this.grup_id;
      },
      result(data) {
        this.$store.commit("FILEGROUP", data.data.fileShare);
      },
      error(error) {
        console.log(error);
      }
    }
  },
  name: "fileMentor",
  data() {
    return {
      files: null,
      role: localStorage.getItem("role_id"),
      progress: false,
      search: "",
      find: "",
      select_kelompok: null,
      id_group: null,
      src: false,
      color: null
    };
  },
  computed: {
    ...mapState(["userProfile", "fileGroup"]),
    loadTable() {
      return this.$apollo.queries.allGrup.loading;
    },
    user_id() {
      let id = null;
      if (this.userProfile) {
        id = parseInt(this.userProfile.id);
      }
      return id;
    },
    grup_id() {
      let id = null;
      if (this.userProfile) {
        id = parseInt(this.id_group);
      }
      return id;
    }
  },
  components: {
    FileKelompok,
    vSelect
  },
  methods: {
    get_group(id) {
      this.src = false;
      this.id_group = id;
      this.$apollo.queries.fileShare.refetch();
      console.log(id);
    }
  }
};
</script>

<style></style>
