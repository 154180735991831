<template>
  <div>
    <div class="_bg-default pa-2">
    <v-btn class="indigo mb-1" dark @click="helpDoc = true"><v-icon class="mr-1">mdi-help-circle-outline</v-icon> Bantuan</v-btn>
    <TutorDocumen v-bind:helpDoc="helpDoc" @close="close"/>
      <section class="indigo pa-2 rounded d-flex">
        <img src="../../assets/img/File.svg" height="100px" />
        <div>
          <h1 class="white--text">Dokumen</h1>
        </div>
      </section>
      <div v-if="role == 4">
        <section class="px-3">
          <v-row>
            <v-col cols="12" md="1">Isi tipe</v-col>
            <v-col cols="12" md="11">
              <v-select
                :items="selectType"
                v-model="tipe"
                item-text="state"
                item-value="abbr"
                solo
                hide-details
                required
                label="pilih tipe.."
              ></v-select>
            </v-col>
          </v-row>
        </section>
        <section v-if="tipe == 'FILE'">
          <v-file-input
            v-model="files"
            color="deep-purple accent-4"
            counter
            prepend-icon="mdi-upload"
            label="Dokumen input"
            placeholder="Klik disini untuk unggah Dokumen dan tidak boleh gambar"
            hide-details="auto"
            solo
            :show-size="1000"
            @change="uploadFile"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>
          <div class="px-8">
            <div v-if="loading" class="mb-2">
              Mengunggah...
              <v-progress-circular
                indeterminate
                size="20"
                color="indigo"
                class="ml-3"
              ></v-progress-circular>
            </div>
            <v-snackbar
              v-model="snack"
              :color="color"
              absolute
              top
              :timeout="timeout"
            >
              {{ response }}
              <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false" dark>
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </section>
        <section v-if="tipe == 'URL'" class="mb-5">
          <v-row>
            <v-col cols="12" md="10">
              <v-text-field
                label="Bagikan link"
                solo
                class="src"
                required
                v-model="url"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="indigo" dark @click="uploadLink"
                >bagikan link</v-btn
              >
            </v-col>
          </v-row>
        </section>
      </div>
      <section class="px-3">
        <v-card>
          <v-tabs
            v-model="tab"
            class="head-tab"
            centered
            icons-and-text
            background-color="indigo"
            dark
            v-if="role == 4"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Dokumen Saya
              <v-icon>mdi-file-outline</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Dokumen Kelompok
              <v-icon>mdi-file-multiple-outline</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat class="pa-2">
                <FileIndividu v-on:refetch="refetchFile" />
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card flat class="pa-2">
                <FileKelompok v-on:refetch="refetchFile" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <div v-if="role == 3">
          <FileMentor v-bind:id_group="id_group" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FileIndividu from "../../components/Student/file/fileIndividu";
import FileKelompok from "../../components/Student/file/fileKelompok";
import FileMentor from "../../components/Teacher/fileMentor";
import TutorDocumen from "../../components/Student/tutorial/tutorDocumen";
import { mapState } from "vuex";
import {
  MY_FILE,
  FILE_GROUP,
  UPLOAD_FILE,
  CREATE_LINK
} from "../../graphql/Student";
import { NAME_GROUP } from "../../graphql/Group";
import Swal from "sweetalert2";

export default {
  apollo: {
    allGrup: {
      query: NAME_GROUP,
      variables() {
        return { page: 1, first: 10, search: this.find };
      }
    },
    fileByUser: {
      variables() {
        return { user_id: this.user_id };
      },
      skip() {
        return !this.user_id;
      },
      query: MY_FILE,
      result(data) {
        this.$store.commit("MYFILE", data.data.fileByUser);
      },
      error(error) {
        console.log(error);
      }
    },
    fileShare: {
      variables() {
        return { grup_id: this.grup_id, share_to_grup: 1 };
      },
      query: FILE_GROUP,
      skip() {
        return !this.grup_id;
      },
      result(data) {
        this.$store.commit("FILEGROUP", data.data.fileShare);
      },
      error(error) {
        console.log(error);
      }
    }
  },
  name: "file",
  data() {
    return {
      files: null,
      tab: null,
      url: "https://",
      loading: false,
      snack: false,
      timeout: 3000,
      response: null,
      role: localStorage.getItem("role_id"),
      id_group: null,
      color: null,
      tipe: "FILE",
      selectType: [
        { state: "Dokumen", abbr: "FILE" },
        { state: "URL", abbr: "URL" },
      ],
      helpDoc: false,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    loadTable() {
      return this.$apollo.queries.allGrup.loading;
    },
    user_id() {
      let id = null;
      if (this.userProfile) {
        id = parseInt(this.userProfile.id);
      }
      return id;
    },
    grup_id() {
      let id = null;
      if (this.role == 4) {
        if (this.userProfile) {
          id = parseInt(this.userProfile.grup.id);
        }
      }
      return id;
    }
  },
  components: {
    FileIndividu,
    FileKelompok,
    FileMentor,
    TutorDocumen
  },
  methods: {
    close() {
      this.helpDoc = false
    },
    uploadFile() {
      if (this.files) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: UPLOAD_FILE,
            variables: {
              user_id: this.user_id,
              grup_id: this.grup_id,
              nama: this.files,
              keterangan: null,
              share_to_grup: 0
            },
            context: {
              hasUpload: true
            }
          })
          .then(() => {
            this.files = null;
            this.refetchFile(0);
            this.snack = true;
            this.loading = false;
          })
          .catch(err => {
            console.log(err.graphQLErrors);
            this.response = err.graphQLErrors[0].message;
            this.color = "red";
            this.files = null;
            this.snack = true;
            this.loading = false;
            console.log("error");
          });
      }
    },
    uploadLink() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_LINK,
          variables: {
            user_id: this.user_id,
            grup_id: this.grup_id,
            nama: this.url,
            tipe: "URL",
            keterangan: null,
            share_to_grup: 0
          },
          context: {
            hasUpload: true
          }
        })
        .then(() => {
          this.url = "";
          this.refetchFile(0);
          this.snack = true;
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Share link berhasil",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err.graphQLErrors);
          this.files = null;
          Swal.fire({
            icon: "error",
            title: err.graphQLErrors[0].message,
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true
          });
          this.loading = false;
        });
    },
    refetchFile(evt) {
      if (evt == 0) {
        this.$apollo.queries.fileByUser.refetch();
      } else {
        this.$apollo.queries.fileByUser.refetch();
        this.$apollo.queries.fileShare.refetch();
        this.tab = "tab-2";
      }
    }
  }
};
</script>

<style>
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
