import gql from "graphql-tag";

//  CREATE STUDENT
export const ADD_STUDENT = gql`
  mutation(
    $nama_lengkap: String!
    $email: String!
    $grup_id: Int!
    $role_id: roleId!
    $created_by: Int!
  ) {
    createStudent(
      input: {
        user: {
          create: {
            nama_lengkap: $nama_lengkap
            email: $email
            grup_id: $grup_id
            role_id: $role_id
            created_by: $created_by
          }
        }
      }
    ) {
      id
      user_id
      user {
        id
        name
        nama_lengkap
        email
      }
    }
  }
`;

// READ STUDENT
export const GET_STUDENT = gql`
  query($page: Int!, $first: Int!, $search: String) {
    allStudent(
      page: $page
      first: $first
      search: { search: $search, role_id: 4 }
    ) {
      data {
        id
        name
        nama_lengkap
        email
        role_id
        tipe_avatar
        student {
          id
          tempat_lahir
          tanggal_lahir
          no_peserta
          nik
          nip
          golongan
          pangkat
          jabatan
          unit_kerja
          instansi
          jenis_kelamin
          agama
          alamat_tinggal
          alamat_ktp
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;

// TOTAL STUDENT
export const COUNT_STUDENT = gql`
  query($page: Int!, $first: Int!) {
    scopeStudent(page: $page, first: $first) {
      paginatorInfo {
        total
      }
    }
  }
`;

// HISTORY TRANSAKSI POIN
export const HISTORY_POINT = gql`
  query( $user_id: ID!, $page: Int!, $first: Int!) {
    point(user_id: $user_id, page: $page, first: $first) {
      paginatorInfo {
        total
      }
      data {
        id
        user_id
        tipe
        jumlah
        keterangan
        user{
          id
          name
        }
      }
    }
  }
`;

// READ STUDENT by id
export const GET_STUDENT_BY_ID = gql`
  query($id: ID!) {
    studentById(id: $id) {
      id
      name
      nama_lengkap
      email
      role_id
      student {
        id
        user_id
        nik
        nip
        golongan
        pangkat
        jabatan
        unit_kerja
        instansi
        jenis_kelamin
        agama
        alamat_tinggal
        alamat_ktp
        tempat_lahir
        tanggal_lahir
      }
    }
  }
`;

export const EDIT_STUDENT = gql`
  mutation(
    $id: ID!
    $connect: ID!
    $id_profile: ID!
    $nama_lengkap: String
    $email: String
    $password: String
    $no_peserta: String
    $nik: String
    $nip: String
    $golongan: String
    $pangkat: String
    $jabatan: String
    $unit_kerja: String
    $instansi: String
    $jenis_kelamin: String
    $agama: String
    $tempat_lahir: String
    $tanggal_lahir: Date
    $alamat_tinggal: String
    $alamat_ktp: String
    $avatar: Int
    $tipe_avatar: String
    $nilai_objektif_teacher: Int
    $nilai_objektif_mentor: Int
  ) {
    updateStudent(
      input: {
        id: $id
        nama_lengkap: $nama_lengkap
        email: $email
        password: $password
        password_no_hash: $password
        avatar: $avatar
        tipe_avatar: $tipe_avatar
        student: {
          connect: $connect
          update: {
            id: $id_profile
            no_peserta: $no_peserta
            nik: $nik
            nip: $nip
            golongan: $golongan
            pangkat: $pangkat
            jabatan: $jabatan
            unit_kerja: $unit_kerja
            instansi: $instansi
            jenis_kelamin: $jenis_kelamin
            agama: $agama
            tempat_lahir: $tempat_lahir
            tanggal_lahir: $tanggal_lahir
            alamat_tinggal: $alamat_tinggal
            alamat_ktp: $alamat_ktp
            nilai_objektif_teacher: $nilai_objektif_teacher
            nilai_objektif_mentor: $nilai_objektif_mentor
          }
        }
      }
    ) {
      id
      name
      nama_lengkap
      email
      tipe_avatar
    }
  }
`;

//DELETE STUDENT
export const DELETE_STUDENT = gql`
  mutation($id: ID!) {
    deleteStudent(id: $id) {
      id
    }
  }
`;
// buat atsk
export const CREATE_TASK = gql`
  mutation(
    $grup_id: Int!
    $user_id: Int
    $tahap: tahap
    $judul: String
    $keterangan: String
    $start_date: Date
    $end_date: Date
    $prioritas: prioritas
    $created_by: Int
  ) {
    createTask(
      grup_id: $grup_id
      user_id: $user_id
      tahap: $tahap
      judul: $judul
      keterangan: $keterangan
      start_date: $start_date
      end_date: $end_date
      prioritas: $prioritas
      created_by: $created_by
    ) {
      id
      grup_id
      tahap
      judul
      keterangan
      start_date
      end_date
      prioritas
      created_by
    }
  }
`;

//task by group
export const GET_TASK_GROUP = gql`
  query($grup_id: Int!) {
    taskByGrup(grup_id: $grup_id) {
      id
      judul
      tahap
      keterangan
      start_date
      end_date
      prioritas
      created_by
    }
  }
`;

//task by id
export const GET_TASK_BYID = gql`
  query($id: ID!) {
    taskById(id: $id) {
      id
      judul
      tahap
      keterangan
      start_date
      end_date
      prioritas
      created_by
      user {
        id
        nama_lengkap
        data_avatar {
          id
          file
        }
      }
    }
  }
`;
//task by user
export const GET_TASK_BY_USER_ID = gql`
  query($user_id: Int!) {
    taskByUser(user_id: $user_id) {
      id
      judul
    }
  }
`;

//update task /edit task
export const UPDATE_TASK = gql`
  mutation(
    $id: ID!
    $grup_id: Int!
    $tahap: tahap
    $judul: String
    $keterangan: String
    $start_date: Date
    $end_date: Date
    $prioritas: prioritas
  ) {
    updateTask(
      id: $id
      grup_id: $grup_id
      tahap: $tahap
      judul: $judul
      keterangan: $keterangan
      start_date: $start_date
      end_date: $end_date
      prioritas: $prioritas
    ) {
      id
      grup_id
      tahap
      judul
      keterangan
      start_date
      end_date
      prioritas
      created_by
    }
  }
`;

//DELETE TASK
export const DELETE_TASK = gql`
  mutation($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`;

//COMMENT TASK
export const COMMENT_TASK = gql`
  mutation($task_id: Int!, $user_id: Int, $komentar: String) {
    createTaskComment(
      task_id: $task_id
      user_id: $user_id
      komentar: $komentar
    ) {
      id
      komentar
    }
  }
`;

//GET COMMENT BY TASK
export const COMMENT_BY_TASK = gql`
  query($task_id: Int) {
    taskCommentByTask(task_id: $task_id) {
      id
      task_id
      komentar
      user_id
      created_at
      user {
        id
        nama_lengkap
        data_avatar {
          id
          file
        }
      }
    }
  }
`;

//DELETE TASK
export const DELETE_COMMENT_TASK = gql`
  mutation($id: ID!) {
    deleteTaskComment(id: $id) {
      id
    }
  }
`;

//update task /edit task
export const UPDATE_COMMENT_TASK = gql`
  mutation($id: ID!, $task_id: Int!, $user_id: Int, $komentar: String) {
    updateTaskComment(
      id: $id
      task_id: $task_id
      user_id: $user_id
      komentar: $komentar
    ) {
      id
    }
  }
`;

//UPLOAD FILE
export const UPLOAD_FILE = gql`
  mutation(
    $grup_id: Int!
    $user_id: Int!
    $nama: Upload
    $keterangan: String
    $share_to_grup: Int
  ) {
    createFile(
      grup_id: $grup_id
      user_id: $user_id
      nama: $nama
      keterangan: $keterangan
      share_to_grup: $share_to_grup
    ) {
      id
    }
  }
`;

//UPLOAD TUGAS INDIVIDU
export const UPLOAD_TUGAS_INDIVIDU = gql`
  mutation($tugas_id: Int, $student_id: Int, $file: Upload) {
    createTugasIndividu(
      tugas_id: $tugas_id
      student_id: $student_id
      file: $file
    ) {
      id
    }
  }
`;

//UPLOAD TUGAS KELOMPOK
export const UPLOAD_TUGAS_KELOMPOK = gql`
  mutation(
    $user_id: Int
    $tugas_id: Int
    $grup_id: Int
    $file: Upload
    $keterangan: String
  ) {
    createTugasGrup(
      user_id: $user_id
      tugas_id: $tugas_id
      grup_id: $grup_id
      keterangan: $keterangan
      file: $file
    ) {
      id
    }
  }
`;

//TUGAS GRUP BY USER ID
export const TUGAS_GRUP_BY_GRUPID = gql`
  query($grup_id: Int!) {
    tugasGrupByGrupId(grup_id: $grup_id) {
      id
      user_id
      tugas_id
      grup_id
      file
      keterangan
      created_at
      updated_at
      grup {
        id
      }
      user {
        id
        nama_lengkap
      }
    }
  }
`;

//TUGAS INDIVIDU BY STUDENT ID
export const TUGAS_INDIVIDU_BY_STUDENT_ID = gql`
  query($student_id: Int!) {
    tugasIndividuByStudentId(student_id: $student_id) {
      id
      tugas_id
      student_id
      file
      created_at
      updated_at
      user {
        id
        nama_lengkap
      }
    }
  }
`;

//tugas individu by id
export const TUGAS_INDIVIDU_BY_ID = gql`
  query($id: ID!) {
    tugasIndividuById(id: $id) {
      id
      tugas_id
      student_id
      file
      user {
        id
        nama_lengkap
      }
      created_at
    }
  }
`;

//GET FILE INDIVIDU
export const MY_FILE = gql`
  query($user_id: Int!) {
    fileByUser(user_id: $user_id) {
      id
      user_id
      grup_id
      nama
      tipe
      keterangan
      share_to_grup
      created_at
      user {
        id
        nama_lengkap
      }
    }
  }
`;

//GET file group
export const FILE_GROUP = gql`
  query($share_to_grup: Int!, $grup_id: Int!) {
    fileShare(share_to_grup: $share_to_grup, grup_id: $grup_id) {
      id
      user_id
      grup_id
      nama
      tipe
      keterangan
      share_to_grup
      user {
        id
        nama_lengkap
      }
    }
  }
`;

//UPDATE FILE
export const SHARE_FILE = gql`
  mutation($id: ID!, $share_to_grup: Int) {
    shareToGroup(id: $id, share_to_grup: $share_to_grup) {
      id
    }
  }
`;

//DELETE tugas isndividu
export const DELETE_TUGAS = gql`
  mutation($id: ID!) {
    deleteTugasIndividu(id: $id) {
      id
    }
  }
`;

//DELETE FILE
export const DELETE_FILE = gql`
  mutation($id: ID!) {
    deleteFile(id: $id) {
      id
    }
  }
`;

//UPLOAD AVATAR
export const UPLOAD_AVATAR = gql`
  mutation($user_id: Int!, $file: Upload, $poin: Int) {
    uploadPrivateAvatar(user_id: $user_id, file: $file, poin: $poin) {
      id
      user_id
      file
      poin
    }
  }
`;

//share link
export const CREATE_LINK = gql`
  mutation(
    $tipe: tipeFile
    $grup_id: Int!
    $user_id: Int!
    $nama: String
    $keterangan: String
    $share_to_grup: Int
  ) {
    createLink(
      grup_id: $grup_id
      user_id: $user_id
      nama: $nama
      keterangan: $keterangan
      tipe: $tipe
      share_to_grup: $share_to_grup
    ) {
      id
    }
  }
`;

//create badge peserta
export const CREATE_BADGE_PESERTA = gql`
  mutation($user_id: Int, $badge_id: Int) {
    createBadgePeserta(user_id: $user_id, badge_id: $badge_id) {
      id
    }
  }
`;
