<template>
  <div>
    <v-card>
      <div v-if="!fileGroup" class="pa-3">
        memuat...
        <v-progress-circular
          indeterminate
          size="30"
          color="indigo"
          class="ml-3"
        ></v-progress-circular>
      </div>
      <div v-else>
        <section
          style="min-height: 200px"
          class="d-flex justify-center align-center"
          v-if="fileGroup.length < 1"
        >
          Belum ada dokumen
        </section>
        <section style="min-height: 200px" class="d-flex flex-wrap pa-3" v-else>
          <div
            class="temp-file"
            v-for="file in fileGroup"
            :key="file.id"
            @click="viewFile(file)"
          >
            <section class="d-flex">
              <v-icon size="70" color="indigo" v-if="file.tipe == 'FILE'"
                >mdi-file-document</v-icon
              >
              <v-icon size="70" color="indigo" v-if="file.tipe == 'URL'"
                >mdi-file-link</v-icon
              >
            </section>
            <section
              class="d-inline-block text-truncate text-truncate"
              style="max-width: 140px"
            >
              <small>{{ file.nama }}</small>
            </section>
          </div>
        </section>
      </div>
    </v-card>
    <FileDetail
      v-bind:dialogFile="dialogFile"
      v-bind:tipe="tipe"
      v-bind:itemFile="itemFile"
      v-on:false="dialogFalse()"
      v-on:refetch="refetching"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileDetail from "./fileDetail";
import { NAME_GROUP } from "../../../graphql/Group";

export default {
  name: "fileIndivdu",
  apollo: {
    allGrup: {
      query: NAME_GROUP,
      variables() {
        return { page: 1, first: 10, search: this.find };
      }
    }
  },
  data() {
    return {
      hovers: false,
      dialogFile: false,
      itemFile: null,
      tipe: "grup"
    };
  },
  components: {
    FileDetail
  },
  computed: {
    ...mapState(["fileGroup"]),
    loadTable() {
      return this.$apollo.queries.allGrup.loading;
    }
  },
  methods: {
    viewFile(item) {
      this.itemFile = item;
      this.dialogFile = true;
    },
    dialogFalse() {
      this.dialogFile = false;
    },
    refetching(evt) {
      this.$emit("refetch", evt);
      this.dialogFalse();
    }
  }
};
</script>

<style scoped>
.temp-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  height: 120px;
  width: 150px;
  border-radius: 5px;
  margin: 5px;
}
.temp-file:hover {
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  cursor: pointer;
}
</style>
