<template>
  <v-row>
    <v-dialog v-model="helpDoc" persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Dokumen</h3>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="py-3 px-5">
          <section>
            <h3>
              Menu dokumen merupakan menu yang digunakan untuk menyimpan atau
              membagikan file, dan tautan. Fungsi utamanya adalah penyimpanan
              dimana peserta bisa menyimpan file pribadi mereka yang berkaitan
              dengan tugas pelatihan. Selain itu apabila peserta sudah memasuki
              fase kelompok mereka bisa membagikan file atau tautan pribadi
              mereka kepada anggota kelompoknya untuk membantu mereka dalam
              mengerjakan tugas kelompok.
            </h3>
          </section>
          <section>
            <img
              src="../../../assets/img/tutorial/documenPage.jpg"
              width="100%"
              alt="pic"
            />
          </section>
          <div>
            <section>
              <h3>
                1. Peserta dapat memilih jenis file atau tautan untuk dibagikan.
              </h3>
            </section>
            <section>
              <h3>
                2. Setelah itu peserta dapat memilih file atau mengisi tautan
                yang akan disimpan atau nanti akan dibagikan
              </h3>
            </section>
            <section>
              <h3>
                3. Merupakan tab dimana peserta dapat melihat file tautan yang
                disimpan secara pribadi.
              </h3>
            </section>
            <section>
              <h3>
                4. Pada tab ini peserta dapat melihat file atau tautan yang
                dibagikan oleh peserta itu sendiri atau rekan anggota
                kelompoknya.k
              </h3>
            </section>
          </div>
        </div>
        <div class="px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/filedetail.jpg"
              width="100%"
              alt="pic"
            />
          </section>
          <div>
            <section>
              <h3>
                Bila peserta mengklik salah satu file atau tautan maka akan
                muncul detail dokumen seperti berikut.
              </h3>
            </section>
            <section>
              <h3>
                1. Peserta dapat menghapus dokumen atau tautan dengan
                menggunakan tombol hapus.
              </h3>
            </section>
            <section>
              <h3>
                2. Untuk membagikan kepada rekan anggota kelompok peserta dapat
                menggunakan tombol bagikan ke kelompok.
              </h3>
            </section>
            <section>
              <h3>
                3. Untuk mengunduh dokumen peserta dapat menggunakan unduh
                dokumen.
              </h3>
            </section>
          </div>
          <div class="pa-5"></div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "helpDoc",
  props: ["helpDoc"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
