<template>
  <div>
    <div v-if="!dialog_file">
    <v-card>
      <div v-if="!myFile" class="pa-3">
        memuat...
        <v-progress-circular
          indeterminate
          size="30"
          color="indigo"
          class="ml-3"
        ></v-progress-circular>
      </div>
      <div v-else>
        <section
          style="min-height: 200px"
          class="d-flex justify-center align-center"
          v-if="myFile.length < 1"
        >
          Belum ada dokumen
        </section>
        <section style="min-height: 200px" class="d-flex flex-wrap pa-3" v-else>
          <div
            class="temp-file"
            v-for="file in myFile"
            :key="file.id"
            @click="viewFile(file)"
          >
            <section class="d-flex">
              <v-icon size="70" color="indigo" v-if="file.tipe == 'FILE'"
                >mdi-file-document</v-icon
              >
              <v-icon size="70" color="indigo" v-if="file.tipe == 'URL'"
                >mdi-file-link</v-icon
              >
            </section>
            <section
              class="d-inline-block text-truncate text-truncate"
              style="max-width: 140px"
            >
              <small>{{ file.nama }}</small>
            </section>
          </div>
        </section>
      </div>
    </v-card>
    </div>
    <v-row justify="start">
    <v-dialog v-model="dialog_file" persistent max-width="550px" v-if="dialog_file">
    <v-card>
      <v-toolbar dense color="indigo">
        <h3 class="white--text">Dokumen Individu</h3>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('false')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="!myFile" class="pa-3">
        memuat...
        <v-progress-circular
          indeterminate
          size="30"
          color="indigo"
          class="ml-3"
        ></v-progress-circular>
      </div>
      <div v-else>
        <section
          style="min-height: 200px"
          class="d-flex justify-center align-center"
          v-if="myFile.length < 1"
        >
          Belum ada dokumen
        </section>
        <section style="min-height: 200px" class="d-flex flex-wrap pa-3" v-else>
          <div
            class="temp-file"
            v-for="file in myFile"
            :key="file.id"
            @click="sendLink(file)"
          >
            <section class="d-flex">
              <v-icon size="70" color="indigo" v-if="file.tipe == 'FILE'">mdi-file-document</v-icon>
              <v-icon size="70" color="indigo" v-if="file.tipe == 'URL'">mdi-file-link</v-icon>
            </section>
            <section
              class="d-inline-block text-truncate text-truncate"
              style="max-width: 140px"
            >
              <small>{{ file.nama }}</small>
            </section>
          </div>
        </section>
      </div>
    </v-card>
    </v-dialog>
    </v-row>
    <FileDetail
      v-bind:dialogFile="dialogFile"
      v-bind:tipe="tipe"
      v-bind:itemFile="itemFile"
      v-on:false="dialogFalse()"
      v-on:refetch="refetching"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileDetail from "./fileDetail";

export default {
  name: "fileIndivdu",
  props: ["dialog_file",],
  data() {
    return {
      hovers: false,
      dialogFile: false,
      itemFile: null,
      tipe: "individu"
    };
  },
  components: {
    FileDetail
  },
  computed: {
    ...mapState(["myFile"])
  },
  methods: {
    viewFile(item) {
      this.itemFile = item;
      this.dialogFile = true;
    },
    dialogFalse() {
      this.dialogFile = false;
    },
    refetching(evt) {
      this.$emit("refetch", evt);
      this.dialogFalse();
    },
    sendLink(file){
      const link = `https://gamification.psikologicare.com/upload/user/${file.nama}`;
      const url = file.nama;
      console.log(file);
      if(file.tipe == "FILE"){
        this.$store.commit("SEND_LINK", link);
        this.$store.commit("FILE_TIPE", file.tipe);
        this.$emit("false");
      }
      if(file.tipe == "URL"){
        this.$store.commit("SEND_URL", url);
        this.$store.commit("FILE_TIPE", file.tipe);
        this.$emit("false");
      }
    },
  },
};
</script>

<style scoped>
.temp-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
  height: 120px;
  width: 150px;
  border-radius: 5px;
  margin: 5px;
}
.temp-file:hover {
  -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.68);
  cursor: pointer;
}
</style>
