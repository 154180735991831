<template>
  <v-row justify="start">
    <v-dialog v-model="dialogFile" persistent max-width="550px">
      <v-card>
        <v-toolbar dense color="indigo">
          <h3 class="white--text">Detail dokumen</h3>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="$emit('false')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-3" v-if="itemFile">
          <div class="d-flex">
            <section style="width: 15%">
              <v-icon size="70" color="indigo" v-if="itemFile.tipe == 'FILE'"
                >mdi-file-document</v-icon
              >
              <v-icon size="70" color="indigo" v-if="itemFile.tipe == 'URL'"
                >mdi-file-link</v-icon
              >
            </section>
            <section class="pa-1" style="width: 80%">
              <v-row>
                <v-col cols="12" md="5">Nama dokumen</v-col>
                <v-col cols="12" md="6">{{ itemFile.nama }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Diunggah oleh</v-col>
                <v-col cols="12" md="6">{{ itemFile.user.nama_lengkap }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">Dibuat tanggal</v-col>
                <v-col cols="12" md="6">{{
                  moment(itemFile.created_at).format("DD MMMM YYYY")
                }}</v-col>
              </v-row>
            </section>
          </div>
          <div class="ma-3">
            <v-btn
              class="red mr-2"
              dark
              @click="deleteFile(itemFile.id)"
              v-if="tipe == 'individu'"
            >
              hapus
              <v-icon class="ml-1">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              class="indigo mr-2"
              dark
              @click="unshareFile(itemFile)"
              v-if="tipe == 'grup' && id == itemFile.user.id"
            >
              Berhenti berbagi
              <v-icon class="ml-1">mdi-share-off</v-icon>
            </v-btn>
            <v-btn
              class="success mr-2"
              :href="`${env}/upload/user/${itemFile.nama}`"
              target="_blank"
              dark
              v-if="itemFile.tipe == 'FILE'"
            >
              unduh dokumen
              <v-icon class="ml-1">mdi-download</v-icon>
            </v-btn>
            <v-btn
              class="success mr-2"
              :href="`${itemFile.nama}`"
              target="_blank"
              rel="noopener noreferrer"
              dark
              v-if="itemFile.tipe == 'URL'"
            >
              buka tautan
              <v-icon class="ml-1">mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn
              class="indigo"
              dark
              @click="shareFile(itemFile)"
              v-if="tipe == 'individu'"
            >
              bagikan ke kelompok
              <v-icon class="ml-1">mdi-share</v-icon>
            </v-btn>
          </div>
          <v-snackbar
            v-model="success"
            color="indigo"
            absolute
            top
            :timeout="timeout"
          >
            Dokumen berhasil dibagikan
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false" dark>
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import { DELETE_FILE, SHARE_FILE } from "../../../graphql/Student";
import { mapState } from "vuex";

export default {
  name: "fileDetail",

  props: ["dialogFile", "itemFile", "tipe"],
  computed: {
    ...mapState(["fileGroup"])
  },
  data() {
    return {
      moment: moment,
      env: process.env.VUE_APP_GRAPHQL,
      loadShare: false,
      success: false,
      timeout: 3000,
      id: localStorage.getItem("id")
    };
  },
  mounted(){
    console.log(this.itemFile);
  },

  methods: {
    shareFile(file) {
      const id = file.id;
      let check = this.fileGroup.find(file => {
        return file.id == id;
      });
      if (check == undefined) {
        this.$apollo
          .mutate({
            mutation: SHARE_FILE,
            variables: {
              id: id,
              share_to_grup: 1
            }
          })
          .then(() => {
            this.success = true;
            this.$emit("refetch", "share");
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Dokumen sudah ada",
          showConfirmButton: false,
          timer: 3000,
          position: "top",
          toast: true
        });
      }
    },

    unshareFile(file) {
      const id = file.id;
      let check = this.fileGroup.find(file => {
        return file.id == id;
      });
      // if (check == undefined) {
      this.$apollo
        .mutate({
          mutation: SHARE_FILE,
          variables: {
            id: id,
            share_to_grup: 0
          }
        })
        .then(() => {
          console.log(check);
          this.success = true;
          this.$emit("refetch", "share");
        })
        .catch(err => {
          console.log(err);
        });
      // } else {
      Swal.fire({
        icon: "success",
        title: "Dokumen sudah tidak dibagikan",
        showConfirmButton: false,
        timer: 3000,
        position: "top",
        toast: true
      });
      // }
    },

    deleteFile(id) {
      Swal.fire({
        title: "Yakin hapus dokumen?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_FILE,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$emit("refetch", 0);
            });
          Swal.fire({
            icon: "success",
            title: "Dokumen telah terhapus",
            timer: 2000,
            position: "top",
            toast: true
          }).then(() => {});
        }
      });
    }
  }
};
</script>

<style>
.textdecoration {
  text-decoration: none;
}
</style>
